import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { Autocomplete, Box, Button, Grid, OutlinedInput, TextField, Typography } from '@mui/material';
import LoaderBar from '../../components/LoaderBar';
import './OnboardingDemo.scss'
import { useGetOperatorDetailQuery, useOperatorOnboardingMutation } from '../../service/api/meveoApi';
import { computeEmailHash, isEmail, keccak_256 } from '../../utils/helpers';
import { useUploadDocumentsMutation } from '../../service/api/uploadApi';
import config from '../../configs/config';
import { requirePassword } from '../../store/slices/cryptoSlice';
import ShareCopy from '../../components/ShareCopy';


const OnboardingDemo = ({ currentUserWallet: wallet, keyringController, toggleRequirePassword }) => {
  const pageTitle = "Onboarding demo in operator site";
  const [uploadFiles] = useUploadDocumentsMutation();

  const { data: operatorInfo } = useGetOperatorDetailQuery();
  const operatorCode = useMemo(() => operatorInfo?.result?.partnerCode || '', [operatorInfo]);
  const operators = useMemo(() => [operatorCode], [operatorCode]);

  const offers = useMemo(() => ['BASIC', 'STANDARD', 'PLUS'], []);

  const [email, setEmail] = useState('');
  const [operator, setOperator] = useState(operatorCode);
  const [commercialOfferCode, setCommercialOfferCode] = useState('');
  const [photo, setPhoto] = useState('');
  const photoFile = useRef(null);
  const [tokenId, setTokenId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [emailHash, setEmailHash] = useState(false);
  const [url, setUrl] = useState('');
  const [signUpURL, setSignUpURL] = useState('');
  const [certUrl, setCertUrl] = useState('');
  const [error, setError] = useState('');

  const [sendRequest, { isLoading }] = useOperatorOnboardingMutation();

  useEffect(() => {
    document.title = pageTitle;
    toggleRequirePassword();
  }, []);

  const fetchPhoto = (file) => {
    var reader = new FileReader();
    reader.onload = function (event) {
      setPhoto(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  const updateForm = (key, value) => {
    console.log(key, value);
    switch (key) {
      case 'email':
        setEmail(value);
        break;
      case 'operator':
        setOperator(value);
        break;
      case 'commercial_offer':
        setCommercialOfferCode(value);
        break;
      case 'photo':
        if (value.length === 0) break;
        fetchPhoto(value[0]);
        photoFile.current = value[0];
        break;
      case 'tokenId':
        setTokenId(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'emailHash':
        setEmailHash(value);
        break;
      default:
        break;
    }
  }

  const dropHandler = (ev) => {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          fetchPhoto(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      fetchPhoto([...ev.dataTransfer.files][0]);
    }
  }

  const handleSubmit = async (evt) => {
    setError('');
    evt.preventDefault();

    if (!isEmail(email)) {
      return setError(t('pages:onboarding.invalid_email'));
    }

    const file_key = `file-${new Date().getTime()}`;
    let files = new FormData();
    files.append(file_key, photoFile.current);
    files.append('folder', tokenId);

    const uploadResult = await new Promise((resolve) => {
      uploadFiles({
        data: files,
      }).unwrap().then(async response => {
        if (response.status === "success" && !!response.result?.files && response.result.files.length) {
          let uploaded = response.result.files[0].file;
          let url = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${uploaded.key}`;

          resolve({
            filename: `${uploaded.name}`,
            mimeType: uploaded.mimetype,
            url,
          })
        }
      }).catch(err => {
        toast.error(err.message);
        resolve(null);
      });
    })
    const payload = {
      coverImage: uploadResult,
      tpkId: tokenId,
      tokenName: name,
      tokenDescription: description,
      commercialOfferCode,
    }

    if (emailHash === true) {
      const _hash = await computeEmailHash(email);
      payload.emailHash = _hash;
    } else {
      payload.email = email;
    }

    const hash = keccak_256(JSON.stringify({
      tpk_id: tokenId,
      email: emailHash ? payload.emailHash : email,
      commercialOfferCode
    }));
    const signature = await keyringController.signMessage({
      from: wallet.address,
      data: hash,
    });

    payload.signature = signature;

    sendRequest({ data: payload, operator_code: operator })
      .catch((error) => {
        setError(error.message);
        toast.error(error.message);
      })
      .then((resp) => {
        console.log("🚀 ~ .then ~ url:", resp)
        if (resp && resp.data.status === 'success') {
          setUrl(`${window.location.origin}/token/${resp.data.result.uuid}`);
          setCertUrl(resp.data.result.certificateUrl);

          const signupLink = `${window.location.origin}/tpk-signup?tpk_id=${resp.data.result.uuid}&email=${encodeURIComponent(email)}${emailHash ? `&emailHash=${payload.emailHash || ''}` : ``}&name=${encodeURIComponent(name)}&description=${encodeURIComponent(description)}&image=${encodeURIComponent(payload.coverImage?.url || '')}&commercialOfferCode=${commercialOfferCode}&operatorOnboarding=${operator}`;
          setSignUpURL(signupLink);
        } else if (resp) {
          setError(resp.data.result);
          toast.error(resp.data.result);
        }
      });
  }

  return !wallet.isOperator ? <Typography>Available only for operators</Typography> : (
    <div className="onboarding-demo" onDrop={dropHandler} onDragOver={(evt) => evt.preventDefault()}>
      <h1 className="title">{pageTitle}</h1>

      <form onSubmit={handleSubmit}>
        <Grid container p={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="h6">Email</Typography>
              <OutlinedInput
                key="email"
                id="email"
                notched={false}
                placeholder={'john@abc.com'}
                onChange={(evt) => updateForm('email', evt.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
            <Typography variant="h6">Operator</Typography>
            <Autocomplete
              fullWidth
              limitTags={1}
              id="operator-code"
              options={Object.values(operators)}
              getOptionLabel={(option) => option}
              size="small"
              onChange={(event, newValue) => {
                updateForm('operator', newValue);
              }}
              renderInput={(params) => <TextField {...params} placeholder="Select operator" size="small" />}
            />
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
            <Typography variant="h6">Commercial Offer</Typography>
            <Autocomplete
              fullWidth
              limitTags={1}
              id="commercial-offer"
              options={Object.values(offers)}
              getOptionLabel={(option) => option}
              size="small"
              onChange={(event, newValue) => {
                updateForm('commercial_offer', newValue);
              }}
              renderInput={(params) => <TextField {...params} placeholder="Select commercial offer" size="small" />}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="h6">Product photo</Typography>
              <input type='file' id='product-photo' placeholder='Product photo' name='photo' accept="image/png, image/jpeg" onChange={(evt) => updateForm('photo', evt.target.files)} />
              <label className='uploads' htmlFor="product-photo">Drag & drop image Or Click to upload</label>
              {photo && photo.length > 0 && <img src={photo} alt="product" />}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="h6">Token ID</Typography>
              <OutlinedInput
                key="tokenId"
                id="tokenId"
                notched={false}
                placeholder={'Token ID'}
                onChange={(evt) => updateForm('tokenId', evt.target.value)}
                error={!!error}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="h6">Product Name</Typography>
              <OutlinedInput
                key="name"
                id="name"
                notched={false}
                placeholder={'Product name'}
                onChange={(evt) => updateForm('name', evt.target.value)}
                error={!!error}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="h6">Product description</Typography>
              <OutlinedInput
                key="description"
                id="description"
                multiline={true}
                rows={4}
                maxRows={4}
                notched={false}
                placeholder={'Product description'}
                onChange={(evt) => updateForm('description', evt.target.value)}
                error={!!error}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
            <input type="checkbox" id="emailHash" name="emailHash" value="emailHash" onChange={(evt) => updateForm('emailHash', evt.target.checked)} />
            <label htmlFor="emailHash"> Hash email before sending</label>
          </Grid>

          <Grid className="actions" item xs={12} sm={12} md={12} lg={12}>
            {error?.length > 0 && <p className="error">{error}</p>}
            {isLoading ? <LoaderBar size={80} />
              : <Button
                color="secondary"
                variant="contained"
                disabled={!email || !operator || !commercialOfferCode || !photo || !tokenId || !name || !description}
                size='large'
                style={{ width: '100%' }}
                onClick={handleSubmit}>
                {t('common:submit')}
              </Button>
            }
          </Grid>

        </Grid>
      </form>
      <ShareCopy
        visible={!!certUrl}
        title={'Token created successfully'}
        message={'This link opens token details page'}
        rows={[{
          label: `This link opens onboarding signup page`,
          value: signUpURL,
        },
        {
          label: `This link opens a certificate page of the token`,
          value: certUrl,
        }
        ]}
        link={url || ''}
        onCopied={() => toast.success(t('pages:token.link_copied_success'))}
        onDismiss={() => setCertUrl(false)}
      />
    </div>
  )
}

export default connect((state) => {
  const wallet = state?.wallet;
  const crypto = state?.crypto;
  return {
    currentUserWallet: wallet,
    keyringController: crypto?.keyringController,
  };
},
  (dispatch) => ({
    toggleRequirePassword: () => {
      dispatch(requirePassword(null));
    },
  })
)(OnboardingDemo);
