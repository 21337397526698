
import React, { useLayoutEffect, useMemo, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { usePlatformMenus } from "../../configs/routes";
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { usePlatformContext } from './PlatformContext.tsx';
import { t } from 'i18next';
import { persistor } from '../../store/rootReducer';
import { logout } from '../../store/slices/authSlice';
import UserAvatarBalance from './UserAvatarBalance';
import './sidebar.scss';

const MenuItem = ({ item, open }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const platformContext = usePlatformContext();

	return (
		<ListItem className={`platform__menu--item ${item.path === location.pathname ? 'active' : ''}`} key={`route-path-${item.path}`} disablePadding sx={{ display: 'block' }}>
			<ListItemButton
				onClick={() => {
					if (platformContext?.navigationIntercept && platformContext.navigationIntercept()) {
						return;
					} else if (item.action) {
						item.action();
					} else {
						navigate(item.path)
					}
				}}
			>
				{!!item.icon && <ListItemIcon className={`icon icon--${item.icon}`} />}
				<ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
			</ListItemButton>
		</ListItem>
	)
}

const Sidebar = ({ open, partnerCode, lang, currentUserWallet: wallet, userLogout, onClose }) => {
	const navigate = useNavigate();
	const menus = usePlatformMenus();
	const [visibleGroup, setVisibleGroup] = useState({});
	const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

	useLayoutEffect(() => {
		function updateSize() {
			setIsMobile(window.innerWidth < 600);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const mobileMenus = useMemo(() => {
		if (isMobile) {
			return [{
				type: 'group',
				label: t("menu:profile"),
				key: "user-menu",
				items: [
					{
						label: t('common:profile'),
						icon: 'profile',
						action: () => {
							navigate('/profile', { replace: true });
							onClose && onClose();
						},
					},
					{
						label: t('common:security'),
						icon: 'security-user',
						action: () => {
							navigate('/profile?tab=settings', { replace: true });
							onClose && onClose();
						},
					},
					{
						label: t('common:support'),
						icon: 'support-24h',
						action: () => {
							navigate('/support', { replace: true });
						},
					},
					{
						label: t('common:logout'),
						icon: 'logout',
						action: () => {
							userLogout()
							navigate('/', { replace: true });
						},
					},
				]
			}];
		} else {
			return []
		}
	}, [isMobile, open]);

	const operatorMenu = useMemo(() => {
		if (wallet.isOperator) {
			return [
				{
					path: '/operator-onboarding',
					label: t('menu:operator-onboarding'),
					icon: 'document'
				},
			]
		} else {
			return []
		}
	}, [wallet.isOperator]);

	const toggleGroup = (key) => {
		setVisibleGroup(prev => ({
			...prev,
			[key]: !prev[key]
		}));
	}

	return (
		<List className="platform__menu">
			{[...mobileMenus, ...Object.values(menus), ...operatorMenu].map((item, index) => {
				if (item.operator && !partnerCode) return null;
				if (item.type === 'group' && item.items.length > 0) {
					return (
						<React.Fragment key={`route-path-group-${index}`}>
							<ListItem className="platform__menu--group" disablePadding key={`route-group-paths-${item.key}`} sx={{ display: 'block' }} >
								{item.key === 'user-menu'
									? <UserAvatarBalance className={visibleGroup[item.key] ? 'toggle expand' : 'toggle'} onClick={() => toggleGroup(item.key)} />
									: <ListItemText className={`${isMobile ? 'toggle' : ''} ${visibleGroup[item.key] ? 'expand' : ''}`} primary={item.label} sx={{ opacity: open ? 1 : 0 }} onClick={() => toggleGroup(item.key)} />
								}
							</ListItem>
							{(!isMobile || visibleGroup[item.key]) && item.items.map((subItem, subIndex) => {
								return <MenuItem open={open} item={subItem} key={`route-path-${subIndex}`} />
							})}
						</React.Fragment>
					)
				}
				return <MenuItem open={open} item={item} key={`route-path-${index}`} />
			})}
		</List>
	)
}

export default connect(state => {
  const wallet = state?.wallet;
	return {
		lang: state?.wallet?.public?.language,
		partnerCode: state?.wallet?.partnerCode,
    currentUserWallet: wallet,
	}
},
	(dispatch) => {
		return {
			userLogout: () => {
				persistor.purge();
				dispatch(logout());
			},
		};
	}
)(Sidebar);