import config from './config';

const api = {
  basicMethod: {
    jsonrpc: '2.0',
    method: null,
    params: null,
  },
  methods: {
    walletCreation: 'wallet_creation',
    walletUpdate: 'wallet_update',
    walletInfo: 'wallet_info',
    walletReport: 'wallet_report',
    getBalance: 'eth_getBalance',
    getTransaction: 'eth_getTransactionByHash',
    token: {
      privacyUpdate: 'privacy-settings',
      markProofs: `markproof-verification`,
    },
  },
  user: {
    auth: `${config.KEYCLOAK_URL}/realms/meveo/protocol/openid-connect/token`,
    usernameByEmail: `username`,
    account: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/unikbase-account`,
  },
  operator: {
    details: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/operator/operator-details`,
    info: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/operator-info`,
    sendEmail: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/send-email`,
  },
  wallet: {
    info: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/user-wallet-info`,
    token: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/unikbase-token`,
    update: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/wallet_jsonrpc`,
    operator: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/operator-user`,
    tpk_user: `operator-onboarding`,
    archive_token: `archive-unikbase-token`,
    resetNonce: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/reset-nonce`,
    getNonce: 'get-nonce',
    commitNonce: 'commit-nonce',
    rollBackNonce: 'rollback-nonce',
  },
  token: {
    info: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/unikbase-token`,
    transfer: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/transfer-unikbase-token`,
    cancelTransfer: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/cancel-unikbase-token`,
    acceptTransfer: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/accept-unikbase-token`,
    refuseTransfer: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/refuse-unikbase-token`,
    history: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/token-history`,
    updateCoverImage: (tokenId) =>
      `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/unikbase-token/${tokenId}/update-coverimage`,
    // ethscan: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/etherscan/api/`
    rpcProvider: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/jsonrpc`,
    sendMessage: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/operator/message-token-owners`,
    createVerifiablePresentation: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/verifiable-presentation`,
    deactivateVerifiablePresentation: (vpUuid) =>
      `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/verifiable-presentation/${vpUuid}/deactivate`,
    listVerifiablePresentation: (tokenId, offset, limit) =>
      `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/verifiable-presentation?tokenId=${tokenId}&offset=${offset}&limit=${limit}`,
    getVerifiablePresentation: (vpUuid) =>
      `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/verifiable-presentation/${vpUuid}`,
    getVerifiablePresentationTransactionHistory: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/verifiable-presentation/token-history`,
    sharedTokenUrl: (vpUuid) => `${window.location.origin}/token/credentials/presentation?id=${vpUuid}`,
  },
  document: {
    retrieveDocument: (tokenId, documentId) =>
      `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/unikbase-token/${tokenId}/document/${documentId}`,
  },
  crappy: {
    search: 'search',
    detail: 'detail',
    auction: {
      highlight: 'search/auction/highlight',
      detail: 'auction/detail',
    },
  },
  upload: {
    tokenDocument: 'api/file-store/document',
    externalFileDownload: 'api/file-store/download',
  },
  otp: {
    status: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/otpStatus`,
    requestEmail: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/emailOtp`, // /{email}
    verifyEmail: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/verifyOtp`,
    requestPhone: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/otp`, // /{phoneNumber}
  },
  payment: {
    createStripeTopup: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/stripe-topup`,
  },
  onboarding: {
    sale: `${process.env.REACT_APP_SERVER_ADDRESS}/meveo/rest/operator-onboarding-info`,
  },
};

export default api;
